import Vue from 'vue'
// import axios from 'axios'
import axios from './utils/axios'
import App from './App.vue'
import router from './router'
// import store from './utils/store'
import store from './store'
import utils from './utils/index'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// 使用iconfont
import './assets/iconfont/iconfont.css';
import './assets/style/index.css';
// 使用Toast提示框
import Toast from 'muse-ui-toast';

// 因为是移动端，所以不推荐使用element UI
import MuseUI from 'muse-ui';
import 'muse-ui/dist/muse-ui.css';

import VueLuckyCanvas from '@lucky-canvas/vue'

import datepicker from '@nutui/nutui/dist/packages/datepicker/datepicker.js';  // 加载构建后的JS
import '@nutui/nutui/dist/packages/datepicker/datepicker.css';  //加载构建后的CSS
datepicker.install(Vue);

import pageLayout from "./components/pageLayout.vue";
import NavBar from "./components/NavBar.vue";
import common from "./utils/common.js"
import datePicker from "./components/datePicker.vue"

Vue.config.productionTip = false
Vue.prototype.store = store;
Vue.use(ElementUI);

Vue.use(VueLuckyCanvas)


Vue.use(MuseUI)
// museUI使用的Material 可能会被墙故弃用
Vue.use(Toast, {
  position: 'bottom',               // 弹出的位置
  time: 2000,                       // 显示的时长
  closeIcon: 'icon-jifen',               // 关闭的图标
  // close: true,                      // 是否显示关闭按钮
  // successIcon: 'check_circle',      // 成功信息图标
  // infoIcon: 'info',                 // 信息信息图标
  // warningIcon: 'priority_high',     // 提醒信息图标
  // errorIcon: 'warning'              // 错误信息图标
})

// 注册全局组件
Vue.component('pageLayout', pageLayout)
Vue.component('NavBar', NavBar)
Vue.component('datePicker', datePicker)
// 挂载工具
Vue.prototype.$axios = axios;
Vue.prototype.$common = common;
Vue.prototype.$utils = utils;

// 注册节流指令
Vue.directive('throttle', {
  bind: function (el, binding) {
    // 默认一秒触发一次
    let throttleTime = binding.value || 1000;
    let cbFun;
    el.addEventListener(
      "click",
      () => {
        console.log(cbFun);
        if (!el.disabled) {
          el.disabled = true;
          cbFun = setTimeout(() => {
            el.disabled = false;
            cbFun = null;
          }, throttleTime);
        }
      },
      false
    );
  }
})
// axios.defaults.baseURL = '/api';
// axios.defaults.headers.common["token"] = "xxx";
// axios.defaults.headers.post['Content-Type'] = 'application/json';
// axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';

router.beforeEach((to, from, next) => {
  // if (to.name === "login") {
  //   // console.log(localStorage.getItem("isLogin"));
  //   if (utils.getCookie("satoken") && localStorage.getItem("isLogin") != 0) {
  //     next({ name: "user" })
  //   } else {
  //     next()
  //   }
  // }
  next()
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

