<template>
  <div class="page">
    <NavBar title="会员中心"></NavBar>
    <div class="content-box">
      <div class="topback">
        <div class="userback" @click="toQRCode">
          <div class="user">
            <div class="head">
              <img class="img" :src="userInfo.img" v-if="userInfo.img" alt="" />
            </div>
            <div class="userinfoback">
              <div class="userinfo">
                <div>{{ userInfo.userName }}</div>
                <!-- <div class="vip" :style="{ '--color': levelColor }">
                  {{ userInfo.level | levelText }}
                </div> -->
              </div>
              <div>会员ID：{{ userInfo.userID }}</div>
            </div>
          </div>
          <div class="qr">
            <!-- <img src="../assets/img/erweima.png" alt="" /> -->
            <img src="../assets/icon/qrCode.svg" alt="" />
          </div>
        </div>
        <div class="line-h"></div>
        <div class="otherback">
          <div class="jifen">
            <div style="color: #c10a00">{{ userInfo.integralNum }}</div>
            <div>积分</div>
          </div>
          <div class="yue">
            <div style="color: #c47200">{{ userInfo.balanceNum }}</div>
            <div>余额</div>
          </div>
          <div class="youhuiquan">
            <div style="color: #008013">{{ userInfo.couponNum }}</div>
            <div>优惠券</div>
          </div>
        </div>
      </div>
      <div class="options-list">
        <div
          class="option-item"
          v-for="item in optionList"
          :key="item.icon"
          @click="toPage(item)"
        >
          <optionBar v-bind="item"></optionBar>
        </div>
        <mu-button
          full-width
          color="#e47470"
          :style="{ marginTop: '20px' }"
          @click="logout"
          >退出会员系统</mu-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import optionBar from "@/components/optionBar.vue";

export default {
  name: "UserView",
  components: {
    NavBar,
    optionBar,
  },
  data() {
    return {
      optionList: [
        {
          icon: "icon-jifen",
          title: "积分明细",
          name: "integralDetail",
        },
        {
          icon: "icon-jinqian",
          title: "余额明细",
          mt: 10,
          name: "balanceDetail",
        },
        {
          icon: "icon-wodeyouhuiquan",
          title: "优惠券明细",
          mt: 10,
          name: "couponPage",
        },
        {
          icon: "icon-xiaopiao",
          title: "电子小票",
          mt: 10,
          name: "receiptPage",
        },
        {
          icon: "icon-weibiaoti-",
          title: "完善资料",
          mt: 10,
          name: "improvePersonalInfo",
        },
        // {
        //   icon: "icon-shengming1",
        //   title: "声明",
        //   mt: 10,
        //   name: "statementPage",
        // },
        {
          icon: "icon-tishi",
          title: "关于我们",
          mt: 10,
          name: "AboutUs",
        },
      ],
      userInfo: {
        userName: "",
        userID: "",
        couponNum: 0, //优惠券数量
        integralNum: 0, //积分数
        balanceNum: 0, //余额数
        level: "",
        img: "",
      },
    };
  },
  filters: {
    levelText(v) {
      let text = "黄金会员";
      switch (v) {
        case 1:
          text = "普通会员";
          break;
        case 2:
          text = "黄金会员";
          break;
        case 3:
          text = "尊享会员";
          break;
      }
      return text;
    },
  },
  computed: {
    levelColor() {
      let color = "#f3cc61";
      switch (this.userInfo.level) {
        case 1:
          color = "#C4BCA6";
          break;
        case 2:
          color = "#f3cc61";
          break;
        case 3:
          color = "#A20C52";
          break;
      }
      return color;
    },
  },
  methods: {
    toPage(item) {
      let pageName = item.name;
      pageName && this.$router.push({ name: pageName });
    },
    // 获取用户信息
    getUserInfo() {
      const url = "/api/member/getMemInfo";
      this.$axios.post(url).then((res) => {
        if (res.data.code == 200) {
          let result = res.data.data;
          this.userInfo.userName = result.realName;
          this.userInfo.userID = result.memberNo;
          this.userInfo.couponNum = result.couponNum || 0;
          this.userInfo.integralNum = result.integralNum || 0;
          this.userInfo.level = result.level;
          this.userInfo.img = result.photo;
          this.$store.commit("saveUserInfo", result);
          this.$utils.setlocalStorage("userInfo", result);
          localStorage.setItem("isLogin", "1");
        } else if (res.data.code == 500) {
          localStorage.setItem("isLogin", "0");
          if (res.data.msg === "未登录") {
            this.$router.push({ name: "login" });
          } else {
            this.$message({
              type: "error",
              message: res.data.msg,
              duration: 2000,
            });
          }
        }
      });
    },
    // 获取余额信息
    getBalanceInfo() {
      const url = "/api/balance/getBalanceDetail";
      this.$axios
        .post(url, {
          pageNo: 1,
          pageSize: 10,
        })
        .then((res) => {
          if (res.data.code == 200) {
            let result = res.data.data;
            console.log(result);
            this.userInfo.balanceNum = result.count || 0;
            this.$store.commit("chageBalanceInfo", result);
          } else if (res.data.code == 500) {
            this.$message({
              type: "error",
              message: res.data.msg,
              duration: 2000,
            });
          }
        });
    },
    // 登出
    logout() {
      // 取消免密和清除个人信息的缓存
      localStorage.setItem("isLogin", "0");
      localStorage.removeItem("userInfo");
      this.$router.push({ name: "login" });
    },
    // 打开个人二维码也米娜
    toQRCode() {
      this.$router.push({ name: "QRCode" });
    },
  },
  created() {
    this.getUserInfo();
    // this.getBalanceInfo();
  },
};
</script>

<style scoped>
.page {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
}
.content-box {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.topback {
  margin: 16px;
  height: 120px;
  background-color: #f5f5f5;
  border-radius: 10px;
  font-size: 12px;
}
.userback {
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.otherback {
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
.line-h {
  background-color: #d8d8d8;
  height: 1px;
}
.user {
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.userinfo {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.userinfoback {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.head {
  width: 40px;
  height: 40px;
  background-color: #5b9de8;
  border-radius: 20px;
}
.img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.vip {
  margin-left: 10px;
  color: var(--color);
  border: 1px solid var(--color);
  padding: 2px;
  border-radius: 6px;
}
.qr {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}
.qr img {
  width: 100%;
  height: 100%;
}
</style>

<style scoped>
.options-list {
  flex: 1;
  margin: 0 16px;
}
</style>