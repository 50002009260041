import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
const store = new Vuex.Store({
  state: {
    phone: "", //用户手机号
    balanceInfo: {}, //余额信息
    userInfo: {}
  },
  //修改state
  mutations: {
    savePhone(state, params) {
      state.phone = params
    },
    chageBalanceInfo(state, params) {
      state.balanceInfo = Object.assign({}, params)
    },
    saveUserInfo(state, params) {
      state.userInfo = params
    },
  },
  actions: {},
  getters: {},
  modules: {}
})
export default store