import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '../views/HomeView.vue'
import MineView from '../views/MineView.vue'
import LoginView from '../views/LoginView.vue'
import RegisterView from '../views/RegisterView.vue'
import UserView from '../views/UserView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    // redirect: '/61/activitiesPage',
    redirect: '/login',
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/register',
    name: 'register',
    component: RegisterView
  },
  {
    path: '/user',
    name: 'user',
    component: UserView,
    meta: {
      title: '会员信息'
    }
  },
  {
    path: '/AboutUs',
    name: 'AboutUs',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/AboutUs.vue'),
    meta: {
      title: '关于我们'
    }
  },
  {
    path: '/mine',
    name: 'mine',
    component: MineView,
  },
  {
    path: '/QRCode',
    name: 'QRCode',
    component: () => import("../views/QRCode.vue"),
    meta: {
      title: '会员码'
    }
  },
  {
    path: '/balanceDetail',
    name: 'balanceDetail',
    component: () => import("../views/balanceDetail.vue"),
    meta: {
      title: '余额明细'
    }
  },
  {
    path: '/integralDetail',
    name: 'integralDetail',
    component: () => import("../views/integralDetail.vue"),
    meta: {
      title: '积分明细'
    }
  },
  {
    path: '/receiptPage',
    name: 'receiptPage',
    component: () => import("../views/receiptPage.vue"),
    meta: {
      title: '电子小票'
    }
  },
  {
    path: '/couponPage',
    name: 'couponPage',
    component: () => import("../views/couponPage.vue"),
    meta: {
      title: '优惠券'
    }
  },
  {
    path: '/receiptDetail',
    name: 'receiptDetail',
    component: () => import("../views/receiptDetail.vue"),
    meta: {
      title: '小票详情'
    }
  },
  {
    path: '/improvePersonalInfo',
    name: 'improvePersonalInfo',
    component: () => import("../views/improvePersonalInfo.vue"),
    meta: {
      title: '完善个人资料'
    }
  },
  {
    path: '/statementPage',
    name: 'statementPage',
    component: () => import("../views/statementPage.vue"),
    meta: {
      title: '法律声明'
    }
  },
  {
    path: '/serviceTerms',
    name: 'serviceTerms',
    component: () => import("../views/serviceTerms.vue"),
    meta: {
      title: '用户服务条款'
    }
  },
  {
    path: '/forgetPwd',
    name: 'forgetPwd',
    component: () => import("../views/forgetPwd.vue"),
    meta: {
      title: '修改密码'
    }
  },
  {
    path: '/61/activitiesPage',
    name: 'x',
    component: () => import("../views/61/activitiesPage.vue"),
    meta: {
      title: '活动首页'
    }
  },
  {
    path: '/61/activitiesRank',
    name: 'activitiesRank',
    component: () => import("../views/61/activitiesRank.vue"),
    meta: {
      title: '活动排名'
    }
  },
  {
    path: '/61/allPlayerPage',
    name: 'allPlayerPage',
    component: () => import("../views/61/allPlayerPage.vue"),
    meta: {
      title: '全部参赛选手'
    }
  },
  {
    path: '/61/joinActivities',
    name: 'joinActivities',
    component: () => import("../views/61/joinActivities.vue"),
    meta: {
      title: '参加活动'
    }
  },
  {
    path: '/61/luckyDrawPage',
    name: 'luckyDrawPage',
    component: () => import("../views/61/luckyDrawPage.vue"),
    meta: {
      title: '抽奖页面'
    }
  },
  {
    path: '/61/playerDetail',
    name: 'playerDetail',
    component: () => import("../views/61/playerDetail.vue"),
    meta: {
      title: '选手详情'
    }
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
