<template>
  <div id="datePicker">
    <div class="datePickerFormControl" @click.stop="isVisible = true">
      <el-input
        v-model="dateValue"
        :placeholder="placeholder"
        disabled
      ></el-input>
    </div>
    <nut-datepicker
      type="date"
      :isVisible="isVisible"
      :title="placeholder"
      startDate="1940-01-01"
      defaultValue="1990-01-01"
      @choose="choose"
      @close="isVisible = false"
    >
    </nut-datepicker>
  </div>
</template>

<script>
export default {
  name: "datePicker",
  data() {
    return {
      dateValue: "",
      isVisible: false,
    };
  },
  props: {
    placeholder: {
      type: String,
      default: "请选择日期",
    },
    date: {
      type: [String, null, undefined],
      require: true,
    },
  },
  methods: {
    choose(date) {
      this.dateValue = date[3];
      this.$emit("update:date", date[3]);
    },
  },
  created() {
    this.dateValue = this.date || "";
  },
};
</script>
