import axios from 'axios'
import router from "../router/index"
// import store from '../store'
//单独引入element的Message组件，以使用
import { Message } from 'element-ui'
//配置基础url、超时时间、post请求头
// http://39.104.85.16:8888
axios.defaults.baseURL = process.env.NODE_ENV === 'production' ? 'http://www.yikbh.com.cn/memberSrv' : 'http://localhost:8080';
// axios.defaults.baseURL = 'http://39.104.85.16:8888';
axios.defaults.timeout = 1000 * 60;
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';

const instance = axios.create({});

axios.post = (url, params, config = {}) => {
  let newUrl = url.split("/api")[1];
  return instance.post(process.env.NODE_ENV === 'production' ? url : newUrl, params, config)
}


//------------------请求拦截-------------------//
instance.interceptors.request.use(config => {
  //例：若存在token则带token
  // const token = store.state.token;
  // if (token) {
  //   config.headers.Authorization = token
  // }
  return config;
}, err => {
  console.log("请求拦截=>", err);
  return err;
})

//------------------响应拦截-------------------//
instance.interceptors.response.use(res => {
  // console.log("响应拦截=>", res.data);
  //例：后端数据处理错误，并返回错误原因；前端获取错误原因并展示
  // if (res.data.success == false) {
  //   Message({
  //     message: res.data.data.message + '，请重试！',
  //     type: 'warning'
  //   });
  // }
  // 这里要判断是否登录失效
  if (res.data.code == 500) {
    if (res.data.msg === "未登陆") {
      router.push({ name: "login" });
    }
    else {
      Message({
        type: "error",
        message: res.data.msg,
        duration: 2000,
      });
    }

  }
  // return res ? res.data : res;
  return res
}, err => {
  console.log(err);
  //打印完整的错误信息
  console.log("响应拦截错误完整信息=>", err.response)
  //也可以在这里对不同的错误码做不同的展示处理
  throw err;
})

export default instance