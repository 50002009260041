export default {
  setCookie(key, value, time) {
    let oDate = new Date();
    oDate.setDate(oDate.getDate() + time);
    document.cookie = key + "=" + value + "; expires=" + oDate.toDateString();
  },
  getCookie(key) {
    let cookieStr = document.cookie;
    let obj = {};
    cookieStr.split(";").forEach(item => {
      let tempArr = item.split("=");
      obj[tempArr[0].trim()] = tempArr[1]
    })
    return obj[key]
  },
  removeCookie(key) {
    this.setCookie(key, "", -1000); // 把cookie设置为过期
  },
  setlocalStorage(key, value) {
    localStorage.setItem(key, JSON.stringify(value))
  },
  getlocalStorage(key) {
    return JSON.parse(localStorage.getItem(key))
  }
}