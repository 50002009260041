<template>
  <!-- 会员信息页面功能选项 -->
  <div class="item" :style="{ height: height + 'px', marginTop: mt + 'px' }">
    <div class="icon">
      <span class="iconfont" :class="icon"></span>
    </div>
    <div class="title">
      {{ title }}
    </div>
  </div>
</template>
<script>
export default {
  name: "optionBar",
  props: {
    icon: {
      type: String,
      require: true,
    },
    title: {
      type: String,
      require: true,
    },
    height: {
      type: [String, Number],
      default: 45,
    },
    mt: {
      type: [String, Number],
      default: 0,
    },
  },
};
</script>
<style scoped>
.item {
  display: flex;
  background-color: #f5f5f5;
}
.icon {
  width: 35px;
  text-align: center;
  line-height: 45px;
}
.title {
  line-height: 45px;
}
</style>