<template>
  <div>
    <NavBar title="会员登录"></NavBar>
    <div class="formback">
      <div class="phoneback">
        <div class="formLabel">手机号码</div>
        <el-input v-model="phone" placeholder="请输入手机号码"></el-input>
      </div>
      <!-- <div class="verifyback">
        <div class="formLabel">验证码</div>
        <el-input v-model="phone" placeholder="请输入验证码"></el-input>
        <el-button class="sendbutton">发送验证码</el-button>
      </div> -->
      <div class="verifyback">
        <div class="formLabel">密码</div>
        <el-input
          v-model="password"
          placeholder="请输入密码"
          show-password
        ></el-input>
      </div>
    </div>
    <el-button class="loginButton" type="danger" @click="login">登录</el-button>
    <div class="register">
      <div class="registerText" @click="gotoregister">前往注册</div>
      <div class="registerText" @click="forget">忘记密码</div>
    </div>
    <div class="tips">
      <el-checkbox v-model="checked"></el-checkbox
      ><span
        ><span class="label-text" @click="checked = !checked"
          >我已阅读并同意</span
        ><a @click="toPage('serviceTerms')">《用户协议》</a
        ><span class="label-text" @click="checked = !checked">和</span
        ><a @click="toPage('statementPage')">《法律声明》</a></span
      >
    </div>
    <div class="aaa">
      <a
        target="_blank"
        style="
          display: inline-block;
          text-decoration: none;
          height: 20px;
          line-height: 20px;
        "
      >
        <img src="../assets/img/record.png" style="float: left" />
        <p
          style="
            float: left;
            height: 20px;
            line-height: 20px;
            margin: 0px 0px 0px 5px;
            color: #939393;
          "
        >
          陕公网安备 61010202000271号
        </p></a
      >
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import NavBar from "@/components/NavBar.vue";
// import global from "../utils/common.js";

export default {
  name: "LoginView",
  components: {
    NavBar,
  },
  data() {
    return {
      phone: "",
      password: "",
      checked: true,
    };
  },
  methods: {
    login() {
      if (!this.phone) {
        this.$message({
          type: "warning",
          message: "请输入手机号码",
          duration: 2000,
        });
        return;
      }
      if (!this.password) {
        this.$message({
          type: "warning",
          message: "请输入密码",
          duration: 2000,
        });
        return;
      }
      if (!this.checked) {
        this.$message({
          type: "warning",
          message: "请阅读下方协议并勾选",
          duration: 2000,
        });
        return;
      }

      // this.$router.push("/user");
      const url = "/api/member/login";
      this.$axios
        .post(url, {
          mobile: this.phone,
          password: this.password,
        })
        .then((res) => {
          if (res.data.code == 200) {
            this.$message({
              type: "success",
              message: "登录成功！",
              duration: 2000,
            });
            // 跳转到用户页面
            this.$router.push({ name: "user" });
          }
        });
    },
    gotoregister() {
      // this.$router.push("/register");
      // var b = global.signCode("test");
      // console.log(b);
      // var e = global.attestationCode("test", b);
      // console.log(e);
      this.$router.push("/register");
    },
    forget() {
      this.$router.push({ name: "forgetPwd" });
    },
    toPage(name) {
      this.$router.push({ name });
    },
  },
  created() {
    // sessionStorage.setItem(this.store.num, "姜");
  },
  mounted() {
    // 这里判断是否免密
    if (
      this.$utils.getCookie("satoken") &&
      localStorage.getItem("isLogin") != 0
    ) {
      this.$router.push({ name: "user" });
    }
  },
};
</script>

<style scoped>
.aaa {
  width: 100%;
  position: fixed;
  bottom: 0px;
  margin: 0 auto;
  padding: 20px 0;
}
.formback {
  margin: auto 20px;
  margin-top: 20px;
  background-color: #f7f7f7;
}
.phoneback {
  margin-top: 10px;
  margin-right: 20px;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.formLabel {
  text-align: right;
  margin-right: 20px;
  width: 60px;
  font-size: 12px;
  flex-shrink: 0;
}
.verifyback {
  margin-right: 20px;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.sendbutton {
  margin-left: 10px;
}
.loginButton {
  margin-top: 20px;
  width: 80%;
}
.register {
  margin-top: 20px;
  padding-right: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.registerText {
  background-color: #fafafa;
  width: 80px;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  border-radius: 10px;
}
.tips {
  font-size: 12px;
  display: flex;
  width: 80%;
  margin: auto;
}
.label-text {
}
</style>
