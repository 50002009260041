<template>
  <div class="nav">
    <div
      class="cancel"
      @click="backClick"
      :class="[!['会员登录', '会员中心'].includes(title) ? '' : 'showBack']"
    >
      <i class="el-icon-close"></i>
    </div>
    <div class="title">{{ title }}</div>
    <div class="right"></div>
  </div>
</template>

<script>
export default {
  name: "NavBar",
  props: {
    title: String,
  },
  methods: {
    backClick() {
      this.title !== "会员登录" && this.$router.back();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.nav {
  background-color: #f5f5f5;
  height: 44px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.cancel {
  margin-left: 0;
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.title {
  text-align: center;
  flex: 1;
  color: black;
  font-size: 18px;
  font-weight: 400;
}
.right {
  margin-right: 0;
  width: 44px;
  height: 44px;
}
.showBack {
  opacity: 0;
}
.el-icon-close {
  font-size: 20px;
}
</style>
