// 定义一些公共的属性和方法
import jsRsasign from "jsrsasign";
const { JSEncrypt } = require("encryptlong");

const baseurl = "xxxx";
const publicKey =
  "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQC35gwWgWMNpqCCbkPVEGe/Ep7YNrvSENdrhNZJfgPN7eqSy+IVItcZ9ZMeDz5licoGO+3lFUYJDnWcwfbc5ZcJbBJ5+KcQiDi3xtPPFo+guhzTFFKPh9WhK4jsnQYIx2/jQRVtYz3O4l1TXPowQ1t6FPycPwrV9M7GysbWwrxq8QIDAQAB";
const privateKey =
  "MIICdgIBADANBgkqhkiG9w0BAQEFAASCAmAwggJcAgEAAoGBALfmDBaBYw2moIJuQ9UQZ78Sntg2u9IQ12uE1kl+A83t6pLL4hUi1xn1kx4PPmWJygY77eUVRgkOdZzB9tzllwlsEnn4pxCIOLfG088Wj6C6HNMUUo+H1aEriOydBgjHb+NBFW1jPc7iXVNc+jBDW3oU/Jw/CtX0zsbKxtbCvGrxAgMBAAECgYEAsbSqBa1ubKiR/rhS8Dd0KZ3yxXjOssX3hTzOXiJZxsubm9NNW6NZPQqj6a5NW411HeUXlAGktee1G5AL35Y1Bg8w9PshHjPrf1TTCJDqAzfb+BMIf3Lif+p5zFrq1fw5Ea9JVaYThlsbHQ9/2JsISLiQRw3i+ta5h4s0aCQWaeECQQDq52G2BbL8jYpPpquFQJw/Wdq07CIepfMIVtfPl+JG6XJMTv0cUkwAIqgjLl/rt8Km0oxzfTgsU1ELmKc1fneFAkEAyGoETIia2ODOVyS90IlIbnGjl9Yt6qlyoXA5eLLPP5XJR4RseslHd5hQARxxBVvL7fZSUqvsNqA4PncSRneDfQJAYUuvTB52MWdK14z4VDwbu52zGecIhttDm7ukreaCIc8FuZep/PYVZqT0MYY+0rqNVF23MQq3npxv4Da9Y0nbpQJAWlM1xsyWhYKkXLApgHAvHd/HrGoSXb2q9UmgDV8BexXmFn5/acOHm0YhYK7R3Wlm+sPWjH4Bwwjo8HY33VyF/QJAMAcxVpaG9Bo5rC1r1jQtmK6pzdI3b887aWkuo/A5XF3j/aBXtS0X1Ycyg8QcajUs7wV1mwO3LXObymVbjp1ZIQ==";

function encryptedData(data) {
  let encryptor = new JSEncrypt();
  encryptor.setPublicKey(publicKey);
  let cptData = encryptor.encryptLong(data);
  return cptData;
}

function decrypt(data) {
  let encryptor = new JSEncrypt();
  encryptor.setPrivateKey(privateKey);
  let result = encryptor.decrypt(data);
  return result;
}

function signCode(strIng) {
  // 创建RSAKey对象
  let rsa = new jsRsasign.RSAKey()
  //因为后端提供的是pck#8的秘钥对，所以这里使用 KEYUTIL.getKey来解析秘钥
  var signPrivateKey =
    "-----BEGIN PRIVATE KEY-----" + privateKey + "-----END PRIVATE KEY-----";
  // 将密钥转码,其实就是将头部尾部默认字符串去掉
  rsa = jsRsasign.KEYUTIL.getKey(signPrivateKey);
  // 创建Signature对象，设置签名编码算法
  // alg:对应的是规则 需要和后端统一
  var sig = new jsRsasign.KJUR.crypto.Signature({
    alg: "SHA1withRSA",
    prov: "cryptojs/jsrsa"
  });
  // 初始化
  sig.init(rsa);
  // 传入待加密字符串
  sig.updateString(strIng);
  // 生成密文
  //@return — Base64URL encoded string
  var sign = jsRsasign.hextob64(sig.sign());
  return sign;
}

function attestationCode(strIng, data) {
  // 创建RSAKey对象
  jsRsasign.RSAKey();
  //因为后端提供的是pck#8的公钥对，所以这里使用 KEYUTIL.getKey来解析公钥
  var signPublicKey =
    "-----BEGIN PUBLIC KEY-----" + publicKey + "-----END PUBLIC KEY-----";
  // 将公钥转码
  signPublicKey = jsRsasign.KEYUTIL.getKey(signPublicKey);
  // 创建Signature对象，设置签名编码算法
  var sig = new jsRsasign.KJUR.crypto.Signature({
    alg: "SHA1withRSA",
    prov: "cryptojs/jsrsa"
  });
  // 初始化
  sig.init(signPublicKey);
  // 传入待加密字符串
  sig.updateString(strIng);
  // !接受的参数是16进制字符串!
  let sign = sig.verify(jsRsasign.b64tohex(data));
  return sign;
}

// 因为每次请求都要携带相关信息，所以这里封装一下
const formatParams = (data = {}) => {
  let merchantNo = "88800000000000000";
  let requestId = "20200101000000";
  let signType = "RSA";
  let g = new Date().getTime();
  let timeStamp = g / 1000;
  let signMap = {
    data,
    merchantNo,
    requestId,
    timeStamp,
    signType,
  };
  //排序
  const ordered = {};
  Object.keys(signMap)
    .sort()
    .forEach((key) => {
      ordered[key] = signMap[key];
    });
  let signJson = JSON.stringify(ordered);
  //签名
  let sign = signCode(signJson);
  return {
    data: JSON.stringify(data),
    merchantNo,
    requestId,
    sign,
    signType,
    timeStamp,
  }
}

// 暴露出属性和方法
export default {
  baseurl,
  publicKey,
  encryptedData,
  decrypt,
  signCode,
  attestationCode,
  formatParams
};
