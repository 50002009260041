<template>
  <div>
    <NavBar title="会员注册"></NavBar>
    <div class="formback">
      <div class="itemback">
        <div class="formLabel">手机号码</div>
        <el-input
          v-model="registerObj.mobile"
          placeholder="请输入手机号码"
        ></el-input>
      </div>
      <div class="itemback">
        <div class="formLabel">姓名</div>
        <el-input
          v-model="registerObj.realName"
          placeholder="请输入姓名"
        ></el-input>
        <el-radio-group
          fill="#e47470"
          size="mini"
          class="radiobutton"
          v-model="registerObj.gender"
        >
          <el-radio-button label="男">男</el-radio-button>
          <el-radio-button label="女">女</el-radio-button>
        </el-radio-group>
      </div>
      <div class="itemback">
        <div class="formLabel">生日</div>
        <!-- <el-date-picker
          v-model="registerObj.birthday"
          type="date"
          placeholder="请选择生日"
          @change="dataSearch"
          value-format="yyyy-MM-dd"
        ></el-date-picker> -->
        <!-- <mu-date-input
          v-model="registerObj.birthday"
          label="请选择生日"
          full-width
          @change="dataSearch"
          value-format="YYYY-MM-DD"
        ></mu-date-input> -->
        <datePicker
          :date.sync="registerObj.birthday"
          placeholder="请选择生日"
        ></datePicker>
      </div>
      <div class="itemback">
        <div class="formLabel">密码</div>
        <el-input
          v-model="registerObj.password"
          placeholder="请输入密码"
          show-password
        ></el-input>
      </div>
      <div class="itemback">
        <div class="formLabel">确认密码</div>
        <el-input
          v-model="password2"
          placeholder="请确认密码"
          show-password
          @blur="checkPassword"
        ></el-input>
      </div>
    </div>
    <el-button class="loginButton" type="danger" @click="register"
      >注册</el-button
    >
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";

export default {
  name: "RegisterView",
  components: {
    NavBar,
  },
  data() {
    return {
      registerObj: {
        birthday: null,
        gender: "男",
        mobile: "",
        password: "",
        realName: "",
      },
      password2: "",
    };
  },
  methods: {
    // 注册
    register() {
      const url = "/api/member/register";
      this.$axios.post(url, this.registerObj).then((res) => {
        console.log(res);
        if (res.data.code == 200) {
          this.$message({
            type: "success",
            message: "注册成功！",
            duration: 2000,
          });
          this.$router.push({ name: "login" });
        } else if (res.data.code == 500) {
          this.$message({
            type: "error",
            message: res.data.msg,
            duration: 2000,
          });
        }
      });
    },
    //密码一致性检验
    checkPassword() {
      if (this.password2 !== this.registerObj.password) {
        this.$message({
          type: "error",
          message: "两次输入的密码不一致！",
        });
      }
    },
    dataSearch() {
      this.getListByDay();
    },
    async initData() {
      //获取当前时间
      var now = new Date();
      var monthn = now.getMonth() + 1;
      var yearn = now.getFullYear();
      var dayn = now.getDate();
      this.selectDay = yearn + "-" + monthn + "-" + dayn;
      this.getListByDay();
    },
    async getListByDay() {
      console.log(this.selectDay);
    },
  },
};
</script>

<style scoped>
.formback {
  margin: auto 20px;
  margin-top: 20px;
  background-color: #f7f7f7;
}
.itemback {
  margin-right: 20px;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.formLabel {
  text-align: right;
  margin-right: 20px;
  width: 60px;
  font-size: 12px;
  flex-shrink: 0;
}
.radiobutton {
  margin-left: 10px;
  flex-shrink: 0;
}
.loginButton {
  margin-top: 20px;
  width: 80%;
}
.register {
  margin-top: 20px;
  margin-right: 10%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.registerText {
  background-color: #f7f7f7;
  width: 100px;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  border-radius: 10px;
}
</style>
