<template>
  <div class="page">
    <NavBar :title="title"></NavBar>
    <div class="content">
      <slot name="content"></slot>
    </div>
  </div>
</template>
<script>
import NavBar from "@/components/NavBar.vue";

export default {
  name: "pageLayou",
  components: {
    NavBar,
  },
  props: {
    title: {
      type: String,
      require: true,
    },
  },
};
</script>
<style scoped>
.page {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.content {
  flex: 1;
  overflow-y: auto;
}
</style>