<template>
  <div>
    <!-- <h1> this is the news page.the transform param is {{this.$route.query.userId}}</h1> -->
    <h1>
      this is the news page.the transform param is
      {{ this.$route.params.userId }}
    </h1>
    <button @click="goMine">请求</button>
  </div>
</template>
<script>
import axios from "axios";

// let Base64 = require('js-base64').Base64

export default {
  methods: {
    goMine: function () {
      // console.log(this.store.num);
      // console.log(sessionStorage.getItem(this.store.num));

      var data = {
        birthday: "2000-01-01",
        gender: "男",
        mobile: "13511112222",
        realName: "张三",
        password: "123",
      };
      var merchantNo = "88800000000000000";
      var requestId = "20200101000000";
      var signType = "RSA";
      var g = new Date().getTime();
      var timeStamp = g / 1000;
      var signMap = {
        data: data,
        merchantNo: merchantNo,
        requestId: requestId,
        timeStamp: timeStamp,
        signType: signType,
      };
      //排序
      const ordered = {};
      Object.keys(signMap)
        .sort()
        .forEach(function (key) {
          ordered[key] = signMap[key];
        });
      //签名

      const url = "/api/member/register";
      axios
        .post(url, data)
        .then(function (response) {
          console.log("response==" + response);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>
